import home from "../static/homeSlide.jpg"
import rush from "../static/rushSlide.jpg"
import about from "../static/aboutSlide.jpg"

export const SlideData = [
  {
    image: home,
  },
  {
    image: about,
  },
  {
    image: rush,
  },
]
